import { get, post } from 'apis';
import AuthRoutes from './Auth.Routes';

const AuthServices = {
  login: async (obj) => {
    const data = await post(AuthRoutes.login, obj);
    return data;
  },
  register: async (obj) => {
    const data = await post(AuthRoutes.register, obj);
    return data;
  },
  verifyToken: async () => {
    const data = await get(AuthRoutes.verifyToken);
    return data;
  },
  getUsers: async () => {
    const data = await get(AuthRoutes.getUsers);
    return data;
  },
}

export default AuthServices