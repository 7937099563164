export const errorHandler = (error) => {
  if (error.response) {
    if (error.response.status === 401) {
      localStorage.clear()
      window.location.reload()
    }
    return error.response.data.error;
  } else if (error.request) {
    return error.message;
  } else {
    return 'Something went wrong';
  }
};
