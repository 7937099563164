import React, { lazy } from "react";
import { Navigate } from "react-router-dom";

const LiveTracking = lazy(() => import('views/live-tracking'));

const DashboardRoutes = [
  {
    path: "/",
    component: <Navigate to="/live-tracking" />,
  },
  // {
  //   path: "*",
  //   component: <Navigate to="/live-tracking" />,
  // },
  {
    path: "/live-tracking",
    component: <LiveTracking />,
  },
]

export default DashboardRoutes
